
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Routes } from "@/router/routes";
import { DxButton } from "devextreme-vue/button";
import { marketRequestModule } from "@/store/modules/marketRequest";
import { MarketRequestActions } from "@/store/modules/marketRequest/actions";
import { MarketRequestGetters } from "@/store/modules/marketRequest/getters";
import {
    MarketRequest,
    MarketRequestState
} from "@/models/request/MarketRequest";
import { isUserInRoles } from "@/services/userUtils";
import { TitleElement } from "@/components/DynamicTitle/TitleElement";
import MessageSendPopup from "@/components/MessageSendPopup";
import RequestInput from "@/components/RequestInput";
import FinalizeRequestConfirmation from "@/components/RequestConfirmationPopup";
import { RequestDetails } from "@/models/request/RequestDetails";
import DynamicTitle from "@/components/DynamicTitle";
import BaseButton from "@/components/BaseButton";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import { FreezeModelsResult } from "@/models/FreezeModelsResult";

@Component({
    components: {
        DxButton,
        MessageSendPopup,
        RequestInput,
        FinalizeRequestConfirmation,
        DynamicTitle,
        BaseButton,
        ConfirmationDialog
    },
    methods: {
        ...marketRequestModule.mapActions({
            loadRequestById: MarketRequestActions.LoadRequestById,
            finalizeRequest: MarketRequestActions.FinalizeRequest,
            exportRequest: MarketRequestActions.Export,
            freezeModelsForRequest: MarketRequestActions.FreezeModelsForRequest
        })
    },
    computed: {
        ...marketRequestModule.mapGetters({
            selectedMarketRequest: MarketRequestGetters.SelectedMarketRequest,
            request: MarketRequestGetters.Request,
            isLoading: MarketRequestGetters.IsLoading,
            freezeModelsResult: MarketRequestGetters.FreezeModelsResult
        })
    }
})
export default class RequestView extends Vue {
    private readonly loadRequestById!: (requestId: number) => Promise<void>;

    protected readonly finalizeRequest!: (message: string) => Promise<void>;
    protected readonly exportRequest!: () => Promise<void>;
    protected readonly freezeModelsForRequest!: () => Promise<void>;

    protected readonly selectedMarketRequest!: MarketRequest | null;
    protected readonly request!: RequestDetails | null;
    protected readonly isLoading!: boolean;
    protected readonly freezeModelsResult!: FreezeModelsResult | null;

    protected finalizeConfirmationPopupVisible = false;
    protected showConfirmationDialog = false;

    protected titleElements: TitleElement[] = [
        { name: "Overview", selected: false, link: Routes.RequestsInProgress },
        { name: "Market Request View", selected: true }
    ];
    protected resultOfSavingFrozenModels = "";

    created(): void {
        if (this.requestId) {
            this.loadRequestById(this.requestId);
        } else {
            this.$router.push(Routes.RequestsInProgress);
        }
    }

    get requestId(): number | null {
        const numberId = Number(this.$route.params.id);
        return !isNaN(numberId) ? numberId : null;
    }

    get isMarketUser(): boolean {
        return isUserInRoles(["MSU", "MU"]);
    }

    get isAdminOrSU(): boolean {
        return isUserInRoles(["Admin", "SU"]);
    }

    get isReadonly(): boolean {
        return (
            !this.isMarketUser ||
            this.selectedMarketRequest?.state ===
                MarketRequestState.Committed ||
            this.selectedMarketRequest?.state === MarketRequestState.Approved
        );
    }

    get showFinalizeAttention(): boolean {
        const approvedMarkets = this.request?.marketRequests.filter(
            (mr) => mr.state === MarketRequestState.Approved
        )?.length;
        return approvedMarkets !== this.request?.marketRequests.length;
    }

    finalize(message: string): void {
        this.finalizeRequest(message).then(() => {
            this.$router.push(Routes.RequestsInProgress);
        });
        this.showConfirmationDialog = false;
    }

    async saveFrozenModelsAndFinalize(message: string): Promise<void> {
        await this.freezeModelsForRequest();

        if (this.freezeModelsResult && !this.freezeModelsResult.success) {
            // Construct the result message dynamically
            let message = "";
            if (
                this.freezeModelsResult.failedMarketRequestCodes &&
                this.freezeModelsResult.failedMarketRequestCodes.length > 0
            ) {
                message =
                    this.freezeModelsResult.failedMarketRequestCodes.join(", ");
            } else {
                message = "No specific market request IDs were reported.";
            }
            this.resultOfSavingFrozenModels = `Saving model settings failed for the following markets: ${message}.\n Should the request be finalized despite errors? (no model settings for mentioned markets will be saved)`;
            this.showConfirmationDialog = true;
        } else {
            this.finalize(message);
        }
    }

    @Watch("$route.path")
    async onCurrentRouteChange(
        newRoute: string,
        oldRoute: string
    ): Promise<void> {
        if (newRoute !== oldRoute && this.requestId)
            this.loadRequestById(this.requestId);
    }
}
